<template>
  <van-pull-refresh v-model="isLoading" pulling-text="Tarik ke bawah untuk refresh..." loosing-text="Lepaskan untuk refresh..."  success-text="Refresh Berhasil" @refresh="onRefresh">
    <div class="authentication">
      <BaseHeaderBar title="Verifikasi Data" />
      <div class="content">
        <VueSlickCarousel :arrows="false" :dots="true" :centerMode="false" :infinite="true" dotsClass="slick-dots" :slidesToShow="1" :slidesToScroll="1" centerPadding="0px" :variableWidth="true">
          <div :style="{ width: '5.613rem' }" class="slick-item">
            <div :class="['authentication-item', { active: authInfo.iddrentityInfoStatus } ]" @click="goIdentity">
              <div class="label">
                <img class="selected-icon" :src="require(`@/assets/img/authentication${authInfo.iddrentityInfoStatus ? '' : '-not'}-selected-icon.png`)" />
                <p>Informasi Identitas</p>
              </div>
              <p v-if="!authInfo.iddrentityInfoStatus" class="status">Belum Verifikasi</p>
              <div class="bottom-block">
                <img class="navigation-icon" :src="require(`@/assets/img/authentication-navigation${authInfo.iddrentityInfoStatus ? '-active' : ''}.png`)" />
                <img class="icon" src="@/assets/img/authentication-icon1.png" />
              </div>
            </div>
          </div>
          <div :style="{ width: '5.613rem' }" class="slick-item">
            <div :class="['authentication-item', { active: authInfo.indrformationStatus } ]" @click="goBasicInfo">
              <div class="label">
                <img class="selected-icon" :src="require(`@/assets/img/authentication${authInfo.indrformationStatus ? '' : '-not'}-selected-icon.png`)" />
                <p>Infomasi Dasar</p>
              </div>
              <p v-if="!authInfo.indrformationStatus" class="status">Belum Verifikasi</p>
              <div class="bottom-block">
                <img class="navigation-icon" :src="require(`@/assets/img/authentication-navigation${authInfo.indrformationStatus ? '-active' : ''}.png`)" />
                <img class="icon" src="@/assets/img/authentication-icon2.png" />
              </div>
            </div>
          </div>
          <div :style="{ width: '5.613rem' }" class="slick-item">
            <div :class="['authentication-item', { active: authInfo.badrnkStatus } ]" @click="goBankInfo">
              <div class="label">
                <img class="selected-icon" :src="require(`@/assets/img/authentication${authInfo.badrnkStatus ? '' : '-not'}-selected-icon.png`)" />
                <p>Informasi Bank</p>
              </div>
              <p v-if="!authInfo.badrnkStatus" class="status">Belum Verifikasi</p>
              <div class="bottom-block">
                <img class="navigation-icon" :src="require(`@/assets/img/authentication-navigation${authInfo.badrnkStatus ? '-active' : ''}.png`)" />
                <img class="icon" src="@/assets/img/authentication-icon3.png" />
              </div>
            </div>
          </div>
        </VueSlickCarousel>
        <van-checkbox v-model="checked">
          <div class="privacy">Mengkonfirmasi dan Menyetujui "<a @click.stop="goAgreement">Perjanjian Pinjaman</a>"</div>
          <template #icon="props">
            <img class="check-icon" :src="require(`@/assets/img/checked-${props.checked ? '' : 'no-'}icon.png`)" />
          </template>
        </van-checkbox>
      </div>
      <button type="button" class="confirm-btn" @click="onSubmit">Kirim</button>
    </div>
    <template #loading>
      <span></span>
    </template>
  </van-pull-refresh>
</template>

<script>
import Vue from 'vue'
import { Checkbox, Toast, PullRefresh } from 'vant'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import request from '@/utils/request'

Vue.use(Checkbox)
Vue.use(Toast)
Vue.use(PullRefresh)

export default {
  name: 'Authentication',
  components: { VueSlickCarousel },
  data () {
    return {
      isLoading: false,
      authInfo: {},
      checked: true
    }
  },
  created () {
    this.getAuthInfo()
  },
  methods: {
    getAuthInfo (isRefresh) {
      request.post('audrthIdentityPage')
        .then(res => {
          console.log('信息认证', res.audrthList, JSON.stringify(res.audrthList))
          this.authInfo = res.audrthList
          isRefresh && (this.isLoading = false)
        })
    },
    goIdentity () {
      if (this.authInfo.iddrentityInfoStatus) {
        Toast('Anda sudah verifikasi data identitas diri')
        return
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/identity',
        query: {
          amount
        }
      })
    },
    goBasicInfo () {
      if (this.authInfo.indrformationStatus) {
        Toast('Anda sudah verifikasi data informasi dasar')
        return
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/basicInfo',
        query: {
          amount
        }
      })
    },
    goBankInfo () {
      if (this.authInfo.badrnkStatus) {
        Toast('Anda sudah verifikasi data bank')
        return
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      if (!this.authInfo.indrformationStatus) {
        Toast('Silakan verifikasi informasi dasar terlebih dahulu')
        return false
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/bankInfo',
        query: {
          type: 'auth',
          amount
        }
      })
    },
    goAgreement () {
      const { amount } = this.$route.query
      this.$router.push({
        path: '/agreement',
        query: {
          amount
        }
      })
    },
    validate () {
      if (!this.checked) {
        Toast('Mengkonfirmasi dan Menyetujui "Perjanjian Pinjaman"')
        return false
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      if (!this.authInfo.indrformationStatus) {
        Toast('Silakan verifikasi informasi dasar terlebih dahulu')
        return false
      }
      if (!this.authInfo.badrnkStatus) {
        Toast('Silakan verifikasi data bank terlebih dahulu')
        return false
      }
      return true
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const { amount } = this.$route.query
      const postData = {
        apdrplicationAmount: amount,
        prdromotionChannel: 'AppStore'
      }
      request.post('sudrbmitloanOrder', postData)
        .then(() => {
          this.$router.push('/')
        })
        .finally(() => {
          this.flag = false
        })
    },
    onRefresh () {
      this.getAuthInfo(true)
    }
  }
}
</script>

<style scoped lang="less">
.authentication {
  display: flex;
  flex-direction: column;
}

.content {
  margin-top: 48px;
  margin-left: 30px;
}

.slick-item {
  display: block!important;
}

.authentication-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 397px;
  height: 233px;
  padding: 11px;
  box-shadow: 0 2px 15px 0 rgba(0,0,0,.16);
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 24px;
  background-color: #FFF;

  &.active {
    background-color: #E92326;

    .label {
      p {
        color: #FFF;
      }
    }

    .navigation-icon {
      width: 80px;
      height: 79px;
    }
  }

  .label {
    display: flex;
    align-items: center;

    p {
      line-height: 42px;
      margin-left: 14px;
      font-size: 30px;
      color: #0F0A3C;
    }
  }

  .selected-icon {
    width: 30px;
    height: 30px;
  }

  .status {
    flex: 1;
    display: flex;
    align-items: center;
    line-height: 22px;
    margin-left: 44px;
    font-size: 22px;
    color: #0F0A3C;

    &::before {
      content: '';
      width: 18px;
      height: 18px;
      margin-right: 10px;
      border-radius: 50%;
      background-color: #0F0A3C;
    }
  }

  .bottom-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .navigation-icon {
    width: 59px;
    height: 59px;
  }

  &:nth-child(1) .icon {
    width: 144px;
    height: 138px;
  }

  &:nth-child(2) .icon {
    width: 105px;
    height: 117px;
  }

  &:nth-child(3) .icon {
    width: 132px;
    height: 105px;
  }
}

/deep/ .slick-dots {
  display: flex!important;
  justify-content: center;
  margin-top: 38px;
  transform: none;

  li {
    width: 117px;
    height: 11px;
    border-radius: 100px;
    opacity: 0.6;
    background-color: #152233;
    cursor: pointer;

    &:nth-child(n+2) {
      margin-left: 22px;
    }

    &.slick-active {
      background-color: #E92326;
    }
  }

  button {
    font-size: 0;
    line-height: 0;
    color: transparent;
    outline: none;
    background: transparent;
  }
}

.check-icon {
  width: 24px;
  height: 24px;
  margin-top: 28px;
}

.privacy {
  line-height: 33px;
  margin-top: 40px;
  margin-left: 5px;
  font-size: 24px;
  color: #333;

  a {
    color: #333;
    text-decoration: underline;
  }
}

.confirm-btn {
  .submit-btn;
  width: 690px;
  margin-top: 87px;
}
</style>
